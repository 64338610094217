import { Plan, PlanCategory, PlanUser, RsvpType, User } from '../../__generated__/graphql'
import './PlanCard.scss'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import People from '../../assets/svg/People.svg'
import Poll from '../../assets/svg/Poll.svg'
import PartyPopperPink from '../../assets/svg/PartyPopperPink.svg'
import { imageSizer } from '../../util/image-sizer'
import { PreviousView } from '../../types/previous-view.enum'
import { PlanCardDateTimeLocation } from './PlanCardDateTimeLocation'
import { getPathForPlan } from '../../constants/path-names'

export const PlanCard = ({
  plan,
  isMorePlansSection,
  category,
}: {
  plan: Plan
  isMorePlansSection?: boolean
  category?: PlanCategory
}) => {
  let previousView: PreviousView | undefined

  if (isMorePlansSection) {
    previousView = PreviousView.morePlansForYou
  } else if (category === PlanCategory.Joined) {
    previousView = PreviousView.joinedFeed
  } else if (category === PlanCategory.Invited) {
    previousView = PreviousView.invitedFeed
  }

  const user = useSelector((state: any) => state.user as User)

  const planUsers: PlanUser[] = plan.planMembers.items
  const planUsersTotal = plan.planMembers.total
  const planOwnerUserName = plan.ownerUser.firstName
  const planOwnerUserId = plan.ownerUserId
  const isPlanOwner = planOwnerUserId === user.id

  const bottomCopyAndIcon = (): { copy: string; icon?: string; className: string; svg?: any } | null => {
    if (isPlanOwner) return null
    if (plan.currentUserRsvp && plan.currentUserRsvp !== RsvpType.Invited) {
      switch (plan.currentUserRsvp) {
        case RsvpType.Yes:
          return { copy: `You RSVP'ed Yes`, icon: 'bi bi-check-circle-fill', className: 'Secondary-text' }
        case RsvpType.Maybe:
          return { copy: `You RSVP'ed Maybe`, icon: 'bi bi-question-circle-fill', className: 'Secondary-text' }
        case RsvpType.No:
          return { copy: `You RSVP'ed No`, icon: 'bi bi-x-circle-fill', className: 'Secondary-text' }
        case RsvpType.Pending:
          return { copy: `Request to join sent!`, icon: 'bi bi-inbox', className: 'Secondary-text' }
      }
    } else if (plan.capacity?.maxCapacity) {
      if (plan.capacity.atCapacity) {
        return {
          copy: 'This plan is full, no RSVPs being accepted.',
          icon: 'bi bi-info-circle',
          className: 'Secondary-text',
        }
      } else if (plan.capacity.maxCapacity) {
        const spotsAvailable = plan.capacity.maxCapacity - plan.capacity.currentCapacity
        return {
          copy: `${spotsAvailable} ${spotsAvailable === 1 ? 'spot' : 'spots'} available!`,
          svg: PartyPopperPink,
          className: 'Pink-text',
        }
      }
    }
    return null
  }
  const bottomCopyAndIconResult = bottomCopyAndIcon()

  return (
    <Link
      to={getPathForPlan(plan.id, previousView === PreviousView.invitedFeed)}
      state={{ previousView }}
      data-cy={plan.title}
    >
      <div className="Plan-card">
        <div className="Plan-card__body">
          <div className="Plan-card__body__columns">
            <div className="Plan-card__body__columns__column-1">
              <div className="Plan-card__body__columns__column-1__row-1">
                {isPlanOwner ? (
                  <p className="Subtitle">You created</p>
                ) : (
                  <p className="Subtitle">{planOwnerUserName} created</p>
                )}
                <h2>{plan.title}</h2>
              </div>
              <div className="Plan-card__body__columns__column-1__row-2 Mt-2">
                <PlanCardDateTimeLocation plan={plan} />
              </div>
              <div className="Plan-card__body__columns__column-1__row-3">
                {planUsers.length !== 0 && (
                  <div className="Plan-card-user-icons-container Mt-2">
                    {planUsers.map(
                      (planUser, i) =>
                        planUser.user.imageUrl && (
                          <img
                            key={i}
                            className="Plan-card-user-icon"
                            src={
                              planUser.user.imageUrl
                                ? imageSizer({ url: planUser.user.imageUrl, width: 300 })
                                : undefined
                            }
                          />
                        ),
                    )}
                    {planUsersTotal > 3 && (
                      <div key="extra" className="Plan-card-user-icon-extra-count">
                        <p className="Plan-card-user-icon-extra-count-text">+{planUsersTotal - 3}</p>
                      </div>
                    )}
                  </div>
                )}
                <div className="Plan-card-pills-container Mt-2">
                  {plan.isVisibilityOpen && plan.ownerUserId === user?.id && (
                    <div className="Plan-card-pill">
                      Open
                      <img className="Plan-card-pill-icon" src={People} />
                    </div>
                  )}
                  {plan.dates && plan.dates.length > 1 && (
                    <div className="Plan-card-pill">
                      Poll
                      <img className="Plan-card-pill-icon" src={Poll} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="Plan-card__body__columns__column-2">
              {plan.imageUrl && <img className="Plan-card__body__columns__column-2__image" src={plan.imageUrl} />}
            </div>
          </div>
        </div>
        {bottomCopyAndIconResult && (
          <>
            <hr className="Plan-card__divider" />
            <div className="Plan-card__footer Flex G-1">
              {bottomCopyAndIconResult.icon && (
                <i className={`Plan-card__footer__icon ${bottomCopyAndIconResult.icon}`}></i>
              )}
              {bottomCopyAndIconResult.svg && <img className="Mr-1" src={bottomCopyAndIconResult.svg} />}
              <p className={`Plan-card__footer__rsvp-status ${bottomCopyAndIconResult.className || ''}`}>
                {bottomCopyAndIconResult.copy}
              </p>
            </div>
          </>
        )}
      </div>
    </Link>
  )
}
