import './SharePlan.scss'
import { Plan } from '../../../__generated__/graphql'
import SendPlan from '../../../assets/svg/SendPlan.svg'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getPathForPlan } from '../../../constants/path-names'

export const SharePlan = ({ plan, dynamicLinkUrl }: { plan: Plan; dynamicLinkUrl: string }) => {
  const navigate = useNavigate()
  const [copyLinkButtonText, setCopyLinkButtonText] = useState('Copy Invite Link')
  const [shareLinkButtonText, setShareLinkButtonText] = useState('Share Invite Link')

  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })

  const copyLink = () => {
    setCopyLinkButtonText('Copied!')
    navigator.clipboard.writeText(dynamicLinkUrl)
    setTimeout(() => {
      setCopyLinkButtonText('Copy Invite Link')
    }, 3000)
  }

  const shareLink = () => {
    try {
      navigator.share({
        title: plan.title,
        url: dynamicLinkUrl,
      })
    } catch (err) {
      setShareLinkButtonText('Copied!')
      navigator.clipboard.writeText(dynamicLinkUrl)
      setTimeout(() => {
        setCopyLinkButtonText('Share Invite Link')
      }, 3000)
    }
  }

  return (
    <div className="SharePlan Flex Flex--col Flex--align-center G-3 Text-centered">
      <div>
        <img className="SharePlan__plan-image" src={plan.imageUrl || ''} />
        <img className="SharePlan__send-plan-image" src={SendPlan} alt="Send Plan" />
      </div>
      <h1 className="Mt-6">Your Pie Plan is ready to share!</h1>
      <div className="Mb-3">
        <p className="Secondary-text">
          Don’t worry if you don’t have all the details yet. Pie makes it easy to work out those details with your
          friends.
          <br />
          <br />
          Invite friends with this link
        </p>
        <p id="link-to-copy">{dynamicLinkUrl}</p>
      </div>
      {isMobile && (
        <button
          onClick={shareLink}
          className="Button-primary Button-large Flex Flex--align-center Flex--centered Fill-width"
        >
          <i className="SharePlan__share-button-icon bi bi-send Mr-2" />
          {shareLinkButtonText}
        </button>
      )}
      <button
        onClick={copyLink}
        className="Button-primary Button-large Flex Flex--align-center Flex--centered Fill-width"
      >
        <i className="SharePlan__copy-button-icon bi bi-files Mr-2" />
        {copyLinkButtonText}
      </button>
      <button onClick={() => navigate(getPathForPlan(plan.id))} className="Button-secondary Button-large Fill-width">
        Done
      </button>
    </div>
  )
}
