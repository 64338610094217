import mixpanel from 'mixpanel-browser'

export const createTelemetryEvent = (event: string, data: any = {}) => {
  const telemetryData = {
    ...data,
    source: 'web',
    path: window.location.pathname,
    host: window.location.host,
  }
  console.log('Telemetry event', telemetryData)
  mixpanel.track(event, telemetryData)
}
