import { isIosDevice } from '../../util/device-info'
import { appStoreUrl } from '../../constants/constants'
import { createMixpanelEvent } from '../../util/create-mixpanel-event'
import { MixpanelEvent } from '../../types/mixpanel-event.enum'

// WARN: target=_blank will break dynamic linking to App Store
export const AppLink = (props: any) => {
  const link = props.dynamicLinkUrl ?? process.env.REACT_APP_HOME_DYNAMIC_LINK

  return (
    <a
      className={props.className}
      href={(isIosDevice() && link) || appStoreUrl}
      onClick={() => {
        createMixpanelEvent(MixpanelEvent.download_app_tapped, { location: 'rsvp' })
      }}
    >
      {props.children}
    </a>
  )
}
