import { useEffect, useState } from 'react'
import { Input } from '../Input/Input'
import './PlanEdit.scss'
import { TextArea } from '../TextArea/TextArea'
import { ButtonHide } from '../ButtonHide/ButtonHide'
import { ConfirmationButtons } from '../ConfirmationButtons/ConfirmationButtons'
import { PlanEditLinkInputs } from './PlanEditLinkInputs/PlanEditLinkInputs'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { PreviousView } from '../../types/previous-view.enum'
import { feedInvitedPathname, feedJoinedPathname, getPathForPlan } from '../../constants/path-names'
import { ButtonsCarousel } from '../ButtonsCarousel/ButtonsCarousel'
import { useMutation } from '@apollo/client'
import { plansCreate } from '../../graphql/mutations/plans-create'
import { EditCity } from '../ProfilePage/EditProfile/EditCity'
import { CreatePlanInput, Place, Plan, PlanLinkInput, UpdatePlanInput } from '../../__generated__/graphql'
import { defaultImageUrl, timezones } from '../../constants/constants'
import { dayjs } from '../../util/dayjs-util'
import { timeToStartTimeText } from '../../util/format-time-to-start-time-text'
import { formatDate } from '../../util/format-date'
import { ChooseImage } from './ChooseImage/ChooseImage'
import axios from 'axios'
import { planIdToChannelId } from '../../util/plan-id-to-channel-id'
import { plansUpdate } from '../../graphql/mutations/plans-update'
import { PlanSettings } from './PlanSettings/PlanSettings'
import { SharePlan } from './SharePlan/SharePlan'
import { planUsersGenerateShareLinks } from '../../graphql/mutations/plan-users-generate-share-links'
import { PlanEditView } from '../../types/PlanEditView.enum'
import React from 'react'

export const PlanEdit = ({ plan }: { plan?: Plan }) => {
  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })
  const authToken = useSelector((state: any) => {
    return state.authToken
  })
  const location = useLocation()
  const navigate = useNavigate()
  const previousView: PreviousView | undefined = location.state?.previousView

  // MARK: Plan Data
  const [planName, setPlanName] = useState<string | null>(plan?.title || null)
  const [planInfo, setPlanInfo] = useState<string>(plan?.description || '')
  const MAXPLANINFOLENGTH = 500
  const [isDisplayingInfo, setIsDisplayingInfo] = useState<boolean>(!!plan?.description || false)

  // MARK: Date
  const systemPlanDate = new Date().toISOString().split('T')[0]
  const [planDate, setPlanDate] = useState<string>(
    plan?.startDateOnly ? `${dayjs(plan.startDateOnly).format('YYYY-MM-DD')}` : systemPlanDate,
  )

  const isDateValid = dayjs(planDate).isValid()
  const [isDisplayingDate, setIsDisplayingDate] = useState<boolean>(!plan?.startDateOnly ? false : true)

  // MARK: Time
  const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [planTimezone, setPlanTimezone] = useState<string>(plan?.timezone || systemTimezone)
  const defaultPlanTime = '16:00'
  const [planTime, setPlanTime] = useState<string>(
    plan?.startTimeOnly ? `${dayjs(plan.startTimeOnly).format('HH:mm')}` : defaultPlanTime,
  )
  const [isDisplayingTime, setIsDisplayingTime] = useState<boolean>(!plan?.startTimeOnly ? false : true)

  // MARK: Location
  const planHasPlace = !!(plan?.place?.venue && plan?.place?.address)
  const [planPlaceText, setPlanPlaceText] = useState<string | null>(
    planHasPlace ? `${plan?.place?.venue}, ${plan?.place?.address}` : null,
  )
  const [planPlace, setPlanPlace] = useState<Place | null>(planHasPlace ? (plan.place as Place) : null)
  const [isDisplayingLocation, setIsDisplayingLocation] = useState<boolean>(planHasPlace || false)

  // MARK: Links
  const initialPlanLinks: PlanLinkInput[] =
    plan?.links?.map((pl) => {
      return { url: pl.url, description: pl.description }
    }) || []
  const [planLinks, setPlanLinks] = useState<PlanLinkInput[]>(initialPlanLinks)

  // MARK: Image
  const [planImageUrl, setPlanImageUrl] = useState<string>(plan?.imageUrl || defaultImageUrl)
  const [customImage, setCustomImage] = useState<string | null>(null)

  // MARK: Plan settings
  const [guestCanInvite, setGuestCanInvite] = useState<boolean>(plan ? plan.guestCanInvite : true)
  const [isSnowball, setIsSnowball] = useState<boolean>(plan ? plan.isSnowball : false)
  const [enableRequestToJoin, setEnableRequestToJoin] = useState<boolean>(
    plan && plan.enableRequestToJoin ? plan.enableRequestToJoin : false,
  )
  const [capacityEnabled, setCapacityEnabled] = useState<boolean>(plan ? !!plan.maxCapacity : false)
  const [maxCapacity, setMaxCapacity] = useState<number | null>(plan && plan.maxCapacity ? plan.maxCapacity : null)

  const [view, setView] = useState<PlanEditView>(location.state?.planEditView || PlanEditView.planEdit)
  const [createdPlan, setCreatedPlan] = useState<Plan | null>(null)
  const [createdPlanDynamicLinkUrl, setCreatedPlanDynamicLinkUrl] = useState<string | null>(null)
  const [confirmationButtonIsDisabled, setConfirmationButtonIsDisabled] = useState<boolean>(
    planName === null || planName === '' || !isDateValid,
  )

  useEffect(() => {
    setConfirmationButtonIsDisabled(
      planName === null || planName === '' || !isDateValid || planInfo?.length > MAXPLANINFOLENGTH,
    )
  }, [planName, isDateValid, planInfo])

  const plansCreateOrUpdateVars: CreatePlanInput | UpdatePlanInput = {
    title: planName,
    timezone: planTimezone || systemTimezone,
    imageUrl: !customImage ? planImageUrl : undefined,
    description: isDisplayingInfo ? planInfo : null,
    links: planLinks.filter((link) => !!link.url),
    dates: isDisplayingDate
      ? [
          {
            text: formatDate(planDate),
            voteUserIds: [''],
          },
        ]
      : [],
    startTimeOnly: isDisplayingTime
      ? dayjs()
          .hour(parseInt(planTime.split(':')[0]))
          .minute(parseInt(planTime.split(':')[1]))
          .second(0)
          .millisecond(0)
      : null,
    startTimeText: isDisplayingTime ? timeToStartTimeText(planTime) : null,
    startDateOnly: isDisplayingDate && isDateValid ? dayjs(planDate).toISOString() : null,
    places:
      isDisplayingLocation && planPlace?.placeId && planPlace?.venue
        ? [
            {
              venue: planPlace.venue,
              address: planPlace.address,
              placeId: planPlace.placeId,
              coordinates: { lat: planPlace.coordinates?.lat, lng: planPlace.coordinates?.lng },
            },
          ]
        : null,
    guestCanInvite,
    isSnowball,
    enableRequestToJoin,
    maxCapacity: capacityEnabled ? maxCapacity : null,
  }

  const [plansCreateMutation] = useMutation(plansCreate, {
    variables: plansCreateOrUpdateVars,
  })
  const [plansUpdateMutation] = useMutation(plansUpdate, {
    variables: { ...plansCreateOrUpdateVars, id: plan?.id },
  })
  const [generateShareLinksMutation] = useMutation(planUsersGenerateShareLinks)

  const uploadPlanImage = async (planId: string) => {
    if (customImage) {
      const formData = new FormData()
      formData.append('file', customImage)
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/channels/${planIdToChannelId(planId)}/icon/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: authToken ? `Bearer ${authToken}` : '',
          },
        },
      )
      return response.data
    }
  }

  const onChooseImage = () => {
    setView(PlanEditView.image)
  }

  const toggleDate = () => {
    setIsDisplayingDate(!isDisplayingDate)
  }

  const toggleTime = () => {
    setIsDisplayingTime(!isDisplayingTime)
  }

  const toggleLocation = () => {
    setIsDisplayingLocation(!isDisplayingLocation)
  }

  const toggleInfo = () => {
    setIsDisplayingInfo(!isDisplayingInfo)
  }

  const onCancelClick = () => {
    if (location.state?.planEditView !== PlanEditView.inviteSettings && view === PlanEditView.inviteSettings) {
      setView(PlanEditView.planEdit)
    } else if (previousView === PreviousView.joinedFeed) {
      navigate(feedJoinedPathname)
    } else if (previousView === PreviousView.planDetailPage && plan) {
      navigate(getPathForPlan(plan.id))
    } else {
      navigate(feedInvitedPathname)
    }
  }

  const savePlan = async () => {
    if (plan) {
      // Updating plan
      setConfirmationButtonIsDisabled(true)
      const response = await plansUpdateMutation()
      setConfirmationButtonIsDisabled(false)
      if (response && response.data?.plans?.update.id) {
        const planId = response.data.plans.update.id
        await uploadPlanImage(planId)
        navigate(`/plans/${planId}`)
      }
    } else {
      // Creating new plan and moving to 'share' step
      setConfirmationButtonIsDisabled(true)
      const response = await plansCreateMutation()
      if (response && response.data?.plans?.create.id) {
        setCreatedPlan(response.data.plans.create)

        const planId = response.data.plans.create.id
        const [uploadImageResponse, { data: generateShareLinksData }] = await Promise.all([
          uploadPlanImage(planId),
          generateShareLinksMutation({ variables: { channelId: planIdToChannelId(planId) } }),
        ])
        if (uploadImageResponse) {
          // tack on the custom imageUrl to the plan create response
          setCreatedPlan({ ...response.data.plans.create, imageUrl: uploadImageResponse.imageUrl })
        }
        if (generateShareLinksData?.planUsers?.generateShareLinks?.dynamicLinkUrl) {
          setCreatedPlanDynamicLinkUrl(generateShareLinksData.planUsers.generateShareLinks.dynamicLinkUrl)
          setView(PlanEditView.share)
        } else {
          console.error('Could not generate dynamic link url for newly created plan!', {
            planResponse: response,
            generateShareLinksData,
          })
          navigate(`/plans/${planId}`)
        }
      } else {
        console.error('Could not create plan!', {
          planResponse: response,
          plansCreateOrUpdateVars,
        })
        // TODO: display error creating plan
      }
      setConfirmationButtonIsDisabled(false)
    }
  }

  const onContinueClick = async () => {
    if (view === PlanEditView.planEdit && !plan) {
      setView(PlanEditView.inviteSettings)
    } else {
      // TODO: add loading state
      await savePlan()
    }
  }

  if (view === PlanEditView.image) {
    return (
      <div className="PlanEdit Card">
        <ChooseImage
          setView={setView}
          planImageUrl={planImageUrl}
          setPlanImageUrl={setPlanImageUrl}
          setCustomImage={setCustomImage}
        />
      </div>
    )
  } else if (view === PlanEditView.inviteSettings) {
    return (
      <div className="PlanEdit Card">
        <PlanSettings
          plan={plan}
          guestCanInvite={guestCanInvite}
          setGuestCanInvite={setGuestCanInvite}
          isSnowball={isSnowball}
          setIsSnowball={setIsSnowball}
          enableRequestToJoin={enableRequestToJoin}
          setEnableRequestToJoin={setEnableRequestToJoin}
          capacityEnabled={capacityEnabled}
          setCapacityEnabled={setCapacityEnabled}
          maxCapacity={maxCapacity}
          setMaxCapacity={setMaxCapacity}
          savePlan={savePlan}
          onCancelClick={onCancelClick}
          confirmationButtonIsDisabled={confirmationButtonIsDisabled}
        />
      </div>
    )
  } else if (view === PlanEditView.share) {
    if (!createdPlan || !createdPlanDynamicLinkUrl) {
      console.error('Attempted to enter share screen without a created plan or dynamic link url!', {
        createdPlan,
        createdPlanDynamicLinkUrl,
        plan,
      })
    } else {
      return (
        <div className="PlanEdit Card Flex Flex--centered">
          <SharePlan plan={createdPlan} dynamicLinkUrl={createdPlanDynamicLinkUrl} />
        </div>
      )
    }
  }

  return (
    <div className="PlanEdit Card">
      <div className="PlanEdit__content Flex Flex--col Flex--align-stretch">
        <div className="PlanEdit__content__container">
          {!isMobile && <h2 className="Pb-6">{plan ? 'Edit Plan' : 'Make a Plan'}</h2>}
          {isMobile && (
            <div className="Flex Flex--col Flex--align-center">
              <h3 className="Pb-6 PlanEdit__content__container__title">Make a Plan</h3>
              <div className="Flex Flex--col PlanEdit__content__container__picture Mb-6">
                <img className="PlanEdit__content__container__picture__image" src={planImageUrl} />
                <button className="Button-small Button-secondary Mt-3" onClick={onChooseImage}>
                  Change Image
                </button>
              </div>
            </div>
          )}
          <div className="Flex">
            <form className="PlanEdit__content__container__form">
              <Input
                className="Mb-12"
                placeHolder="What do you want to do?"
                value={planName ?? ''}
                onChange={(e) => setPlanName(e.target.value)}
              />

              <div>
                {(isDisplayingDate || isDisplayingTime) && (
                  <div className="Mb-12">
                    <h3 className="Mb-3">When</h3>
                    {isDisplayingDate && (
                      <div className="Flex Flex--align-center Mb-6 G-3">
                        <Input
                          error={!isDateValid}
                          errorText={'Please enter a valid date'}
                          placeHolder="Date"
                          inputType="date"
                          minDate={`${dayjs().format('YYYY-MM-DD')}`}
                          value={planDate}
                          onChange={(e) => setPlanDate(e.target.value)}
                        />
                        <ButtonHide onHideButtonClick={toggleDate} />
                      </div>
                    )}
                    {isDisplayingTime && (
                      <div className="Flex Flex--align-center Mb-6 G-3">
                        <div className="PlanEdit__content__container__form__time-and-tz-container">
                          <Input
                            className="PlanEdit__content__container__form__time-input"
                            placeHolder="Start Time"
                            inputType="time"
                            value={planTime}
                            onChange={(e) => setPlanTime(e.target.value)}
                          />
                          <div className="PlanEdit__content__container__form__timezone">
                            <select
                              className="PlanEdit__content__container__form__timezone__select Fill-height"
                              value={planTimezone}
                              onChange={(e) => setPlanTimezone(e.target.value)}
                            >
                              {timezones.map((timezone) => (
                                <option key={timezone.identifier} value={timezone.identifier}>
                                  {`${timezone.label} (${timezone.shorthand})`}
                                </option>
                              ))}
                            </select>
                            <label className="PlanEdit__content__container__form__timezone__select-label">
                              Timezone
                            </label>
                            <i className="PlanEdit__content__container__form__timezone__select-caret bi bi-chevron-down" />
                          </div>
                        </div>
                        <ButtonHide onHideButtonClick={toggleTime} />
                      </div>
                    )}
                  </div>
                )}
                {isDisplayingLocation && (
                  <div>
                    <h3 className="Mb-3">Where</h3>
                    <div className="Flex Mb-12 G-3">
                      <EditCity
                        city={planPlaceText}
                        setCity={setPlanPlaceText}
                        setPlace={setPlanPlace}
                        infoType={null}
                        placeholder={'Location'}
                        displayVenue={true}
                      />
                      <div className="PlanEdit__content__container__form__where_hide_button_wrapper">
                        <ButtonHide onHideButtonClick={toggleLocation} />
                      </div>
                    </div>
                  </div>
                )}
                {isDisplayingInfo && (
                  <div className="Mb-12">
                    <h3 className="Mb-3">More Details</h3>
                    <div className="Flex Fill-width G-3">
                      <div className="Fill-width">
                        <TextArea
                          className="Fill-width"
                          placeHolder="Info"
                          value={planInfo}
                          onChange={(e) => setPlanInfo(e.target.value)}
                        />
                        <p className={`planInfo ${planInfo?.length > MAXPLANINFOLENGTH ? 'planInfoOver' : ''}`}>
                          {planInfo?.length ?? 0} / {MAXPLANINFOLENGTH}
                        </p>
                      </div>
                      <ButtonHide onHideButtonClick={toggleInfo} />
                    </div>
                  </div>
                )}
                {planLinks.length > 0 && <h3 className="Mb-3">Links</h3>}
                <PlanEditLinkInputs planLinks={planLinks} setPlanLinks={setPlanLinks} />
              </div>
            </form>
            {!isMobile && (
              <div className="Flex Flex--col Flex--align-start PlanEdit__content__container__picture">
                <img className="PlanEdit__content__container__picture__image" src={planImageUrl} />
                <button className="Button-small Button-secondary Mt-3" onClick={onChooseImage}>
                  Change Image
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="PlanEdit__content__sticky-container">
          <ButtonsCarousel
            isDisplayingDate={isDisplayingDate}
            isDisplayingTime={isDisplayingTime}
            isDisplayingLocation={isDisplayingLocation}
            isDisplayingInfo={isDisplayingInfo}
            isDisplayingLinks={planLinks.length !== 0}
            toggleDate={toggleDate}
            toggleTime={toggleTime}
            toggleLocation={toggleLocation}
            toggleInfo={toggleInfo}
            toggleLink={() => setPlanLinks([{ url: '', description: '' }])}
          />
          <ConfirmationButtons
            saveButtonText={plan ? 'Done' : 'Continue'}
            onCancelClick={onCancelClick}
            onContinueClick={onContinueClick}
            isDisabled={confirmationButtonIsDisabled}
          />
        </div>
      </div>
    </div>
  )
}
