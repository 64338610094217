import { gql } from '@apollo/client'

export const planUsersFindPlanMembersByRsvpType = gql`
  query planUsersFindPendingPlanMembers($planId: String!, $rsvp: RsvpType!) {
    planUsers {
      find(planId: $planId, rsvp: $rsvp, limit: 2000) {
        items {
          id
          rsvp
          userId
          user {
            id
            firstName
            lastName
            fullName
            imageUrl
          }
        }
      }
    }
  }
`
