import { gql } from '@apollo/client'

export const planUsersFindPlanMembers = gql`
  query planUsersFindPlanMembers($planId: String!) {
    planUsers {
      findPlanMembers(planId: $planId, limit: 2000) {
        items {
          id
          rsvp
          userId
          user {
            id
            firstName
            lastName
            fullName
            imageUrl
          }
        }
      }
    }
  }
`
