/* eslint-disable @typescript-eslint/ban-ts-comment */
import PollCircle from '../../../../assets/svg/PollCircle.svg'
import PollCheckmark from '../../../../assets/svg/PollCheckmark.svg'
import { PollOption, User, PollVote } from '../../../../__generated__/graphql'
import { useSelector } from 'react-redux'
import './ActivePollOption.scss'
import { useMutation } from '@apollo/client'
import { pollCastVote } from '../../../../graphql/mutations/polls-cast-vote'

export const ActivePollOption = ({ option }: { option: PollOption }) => {
  const [pollsCastVoteMutation] = useMutation(pollCastVote, {
    optimisticResponse: {
      polls: {
        castVote: true,
      },
    },
  })

  const user = useSelector((state: any) => state.user as User)

  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })

  const handleVote = (optionId: string) => {
    pollsCastVoteMutation({
      variables: { pollOptionId: optionId },
      update: (cache) => {
        cache.modify({
          id: `PollOption:${optionId}`,
          fields: {
            //@ts-ignore
            votes(existingVotesRefs: PollVote[] = []) {
              const voted = existingVotesRefs.find((vote) => vote.userId === user.id)
              if (voted) {
                return existingVotesRefs.filter((vote) => vote.userId !== user.id)
              } else {
                return [...existingVotesRefs, { userId: user.id }]
              }
            },
          },
        })
      },
    })
  }

  const hasPollOptionMyId = option?.votes.map((vote) => vote.userId).includes(user?.id)

  const votes = option.votes?.length

  return (
    <li
      className={hasPollOptionMyId ? 'ActivePollOption ActivePollOption--selected' : 'ActivePollOption'}
      onClick={() => handleVote(option.id)}
    >
      <div className="ActivePollOption__flex">
        <img className="ActivePollOption__flex__circle" src={hasPollOptionMyId ? PollCheckmark : PollCircle} />
        <p>{option.value}</p>
      </div>
      <div
        className={
          hasPollOptionMyId ? 'ActivePollOption__number ActivePollOption__number--selected' : 'ActivePollOption__number'
        }
      >
        {votes !== 1 && (
          <div className="ActivePollOption__number-flex">
            <p>{votes}</p> {!isMobile && <p className="ActivePollOption__number-flex__number-votes">votes</p>}
          </div>
        )}
        {votes === 1 && (
          <div className="ActivePollOption__number-flex">
            <p>{votes}</p>
            {!isMobile && <p className="ActivePollOption__number-flex__number-votes">vote</p>}
          </div>
        )}
      </div>
    </li>
  )
}
