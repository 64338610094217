import './EnhancedConfirmation.scss'
import type { JSX } from 'react'

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { AddPlanUsersInput, Plan, RsvpType, User } from '../../__generated__/graphql'
import { AppLink } from '../../common-components/AppLink/AppLink'
import Apple from '../../assets/svg/Apple.svg'
import { planUsersAdd } from '../../graphql/mutations/plan-users-add'
import { getPlan } from '../../graphql/queries/plans-plan'
import { planUsersFindPlanMembers } from '../../graphql/queries/plan-users-find-plan-members'
import { apiV1Url } from '../../constants/constants'

import { CALENDAR_VARIANT } from './constants'
import { buildCalendarDateString, createCalendarEvent } from './utils'
import { GetThePieApp } from './GetThePieApp'
import { PublicPlan } from './PublicPlan'
import { CalendarLink } from './CalendarLink'
import { planUsersRequestToJoin } from '../../graphql/mutations/plan-users-request-to-join'

export interface EnhancedConfirmationProps {
  plan: Plan
  rsvpType: RsvpType
}

function EnhancedConfirmation({ plan, rsvpType }: EnhancedConfirmationProps) {
  const user = useSelector((state: any) => state.user as User)
  const [searchParams] = useSearchParams()
  const [publicPlans, setPublicPlans] = useState<any[]>([])

  const planId = plan.id
  const inviterPlanUserId = searchParams.get('inviter') || null

  const [planUsersAddMutation] = useMutation<{ addPlanUsersInput: AddPlanUsersInput }>(planUsersAdd, {
    variables: { planId, rsvp: RsvpType.Yes, inviterPlanUserId },
    refetchQueries: [getPlan, planUsersFindPlanMembers],
  })

  const [planUsersMaybeMutation] = useMutation<{ addPlanUsersInput: AddPlanUsersInput }>(planUsersAdd, {
    variables: { planId, rsvp: RsvpType.Maybe, inviterPlanUserId },
    refetchQueries: [getPlan, planUsersFindPlanMembers],
  })

  const [planUsersRequestToJoinMutation] = useMutation(planUsersRequestToJoin, {
    variables: { planId },
    refetchQueries: [getPlan],
  })

  useEffect(() => {
    const abortController = new AbortController()
    const start = getCurrentDateFormatted()
    const url = `${apiV1Url}/plans?limit=30&start=${start}`
    fetch(url, { signal: abortController.signal })
      .then((response) => response.json())
      .then((data) => {
        setPublicPlans(Array.isArray(data) ? data : [])
      })
      .catch((error) => {
        console.error('Error fetching public plans:', error)
      })

    return () => abortController.abort()
  }, [])

  const isRsvpYES = rsvpType === RsvpType.Yes
  const isRsvpMAYBE = rsvpType === RsvpType.Maybe
  const isRsvpPENDING = rsvpType === RsvpType.Pending

  // FIXME: `planUsersAddMutation` SHOULD execute within button press event handler
  useEffect(() => {
    /**
     * NOTE: Using `planId` and `isRsvpYES` is intended to protect against
     * undesired execution, and ensure `planUsersAddMutation` executes when the
     * `planId` or `isRsvpYES` changes correspond.
     */
    // Verify against `planId` & `isRsvpYES` to prevent undesired execution.
    if (Boolean(planId) && isRsvpYES) {
      planUsersAddMutation().catch((err) => {
        console.error('Unexpected error in planUsers.add mutation', { err, planId, rsvp: RsvpType.Yes })
      })
    }
  }, [planId, isRsvpYES])

  // FIXME: `planUsersRequestToJoinMutation` SHOULD execute within button press event handler
  useEffect(() => {
    /**
     * NOTE: as above, but for `isRsvpPENDING`.
     */
    if (Boolean(planId) && isRsvpPENDING) {
      planUsersRequestToJoinMutation().catch((err) => {
        console.error('Unexpected error in planUsers.requestToJoin mutation', { err, planId, rsvp: RsvpType.Pending })
      })
    }
  }, [planId, isRsvpPENDING])

  // FIXME: `planUsersAddMutation` SHOULD execute within button press event handler
  useEffect(() => {
    /**
     * NOTE: as above, but for `isRsvpMAYBE`.
     */
    if (Boolean(planId) && isRsvpMAYBE) {
      planUsersMaybeMutation().catch((err) => {
        console.error('Unexpected error in planUsers.maybe mutation', { err, planId, rsvp: RsvpType.Maybe })
      })
    }
  }, [planId, isRsvpMAYBE])

  const subtitle = getSubtitleMessage(rsvpType, plan.ownerUser.firstName ?? '')

  const calendarDateString = buildCalendarDateString(plan)
  const event = { ...createCalendarEvent(plan), start: plan.startAt }

  return (
    <article className="EnhancedConfirmation">
      <header className="EnhancedConfirmation__header">
        {(isRsvpYES || isRsvpMAYBE) && (
          <h2 className="EnhancedConfirmation__header__title">
            {isRsvpYES ? `You’re in, ${user.firstName}!` : "so you're saying there's a chance?"}
          </h2>
        )}
        {subtitle}
        {(isRsvpYES || isRsvpMAYBE) && (
          <>
            <p>{plan.title}</p>
            {calendarDateString && (
              <>
                <p className="EnhancedConfirmation__header__datatime">{calendarDateString}</p>
                <div>
                  <h4 className="EnhancedConfirmation__header__subtitle">add it to your calendar</h4>
                  <div className="EnhancedConfirmation__calendars">
                    <CalendarLink
                      type={CALENDAR_VARIANT.Apple}
                      event={event}
                      shouldntOpenNewTab
                      id="apple-invite-link"
                    />
                    <CalendarLink type={CALENDAR_VARIANT.Google} event={event} id="google-invite-link" />
                    <CalendarLink type={CALENDAR_VARIANT.Outlook} event={event} id="outlook-invite-link" />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </header>
      <hr />
      <div className="EnhancedConfirmation__main">
        <GetThePieApp />
        {/* Note: Scrollbar Pattern. Outer view clips, inner view scrolls */}
        <section className="EnhancedConfirmation__main__plans">
          <div className="EnhancedConfirmation__main__plans__scroll">
            {publicPlans.map((aPlan) => (
              <PublicPlan {...aPlan} id={aPlan.id} key={aPlan.id} />
            ))}
          </div>
        </section>
      </div>
      <footer className="EnhancedConfirmation__footer">
        <AppLink className="Download__btn">
          <img className="Download__btn__image" src={Apple} alt="Apple Logo" />
          <span>Download Pie</span>
        </AppLink>
      </footer>
    </article>
  )
}

function getSubtitleMessage(rsvpType: RsvpType, firstName: string): JSX.Element | null {
  switch (rsvpType) {
    case RsvpType.No:
      return <p>got it – it's a no at this time.</p>
    case RsvpType.Pending:
      return <h3>We sent {firstName} your request to join.</h3>
    default:
      return null
  }
}

function getCurrentDateFormatted() {
  const today = new Date()
  const offset = -5 * 60 // Central Daylight Time (CDT) is UTC-5
  const localTime = new Date(today.getTime() + today.getTimezoneOffset() * 60000 + offset * 60000)
  const year = localTime.getFullYear()
  const month = String(localTime.getMonth() + 1).padStart(2, '0')
  const day = String(localTime.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export default EnhancedConfirmation
