import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from '@firebase/auth'
import { FormEvent, useEffect, useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import { E164Number } from 'libphonenumber-js/types'
import 'react-phone-number-input/style.css'
import './AuthPhoneNumber.scss'
import Loader from '../../Loader/Loader'
import { FirebaseErrorCodes } from '../../../types/firebase-error-codes.enum'
import { AuthStepName } from '../../../types/auth-step-name.enum'

const warnOnlyFirebaseCodes = [FirebaseErrorCodes.invalidPhoneNumber, FirebaseErrorCodes.networkRequestFailed]

const AuthPhoneNumber = ({
  setAuthStep,
  setPhoneNumberProp,
  headerIcon,
  headerText,
}: {
  setAuthStep: (step: AuthStepName) => void
  headerIcon: string
  headerText: string
  setPhoneNumberProp: (phoneNumber: string) => void
}) => {
  const [phoneNumber, setPhoneNumber] = useState<E164Number | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [errorCode, setErrorCode] = useState('')

  useEffect(() => {
    if (errorCode) {
      const timer = setTimeout(() => {
        setErrorCode('')
      }, 5000)

      return () => clearTimeout(timer)
    } else {
      return setErrorCode('')
    }
  }, [errorCode])

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    setIsLoading(true)
    e.preventDefault()
    generateRecaptcha()

    const appVerifier = window.recaptchaVerifier
    const auth = getAuth()
    signInWithPhoneNumber(auth, String(phoneNumber), appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult
        setPhoneNumberProp(String(phoneNumber))
        setAuthStep(AuthStepName.verificationCode)
        setIsLoading(false)
      })
      .catch((error) => {
        setErrorCode(error.code)
        if (warnOnlyFirebaseCodes.includes(error.code))
          console.warn('Issue sending SMS code to user', { error, phoneNumber })
        else console.error('Could not send SMS code to user! [AuthPhoneNumber]', { error, phoneNumber })
        setIsLoading(false)
      })
  }

  const generateRecaptcha = () => {
    const auth = getAuth()
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
        'expired-callback': () => {
          // Response expired. Ask user to solve reCAPTCHA again.
        },
      })
    }
  }

  return (
    <div className="PhoneNumber">
      <h1 className="PhoneNumber__header-icon Header-1">{headerIcon}</h1>
      <h2 className="PhoneNumber__header-text Header-2">{headerText}</h2>
      <form className="PhoneNumber__form" onSubmit={handleSubmit}>
        <label>
          <PhoneInput
            className="PhoneNumber__form__input-wrapper"
            countrySelectProps={{ unicodeFlags: true }}
            countryCallingCodeEditable={false}
            placeholder="Phone number"
            defaultCountry="US"
            value={phoneNumber}
            onChange={setPhoneNumber}
          />
        </label>
        {errorCode && errorCode === FirebaseErrorCodes.invalidPhoneNumber && (
          <p className="PhoneNumber__form__error">Please enter a valid phone number.</p>
        )}
        {errorCode && errorCode !== FirebaseErrorCodes.invalidPhoneNumber && (
          <p className="PhoneNumber__form__error">We were unable to send an SMS.</p>
        )}
        {isLoading ? <Loader /> : <button className="Button-primary Button-large Fill-width">Send code</button>}
      </form>
    </div>
  )
}

export { AuthPhoneNumber }
