import 'react-phone-number-input/style.css'
import '../EnhancedAuth.scss'

import type { FormEventHandler, JSX } from 'react'
import { useEffect, useState } from 'react'
import type { E164Number } from 'libphonenumber-js/types'

import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from '@firebase/auth'
import PhoneInput from 'react-phone-number-input'

import Loader from '../../Loader/Loader'
import { AuthStepName } from '../../../types/auth-step-name.enum'
import { Plan } from '../../../__generated__/graphql'

import { WhatIsPie } from './WhatIsPie'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { channelIdToPlanId } from '../../../util/channel-id-to-plan-id'
import { createMixpanelEvent } from '../../../util/create-mixpanel-event'
import { MixpanelEvent } from '../../../types/mixpanel-event.enum'
import { logSmsError } from './utils/LogSmsError'
import { TermsAndService } from './TermsAndService'
import { renderError } from './utils/RenderError'
import Header from './Header'
import { createTelemetryEvent } from '../../../util/create-telemetry-event'
import { isInAppBrowser } from '../../../util/detect-in-app-browser'

export interface EnhancedAuthPhoneNumberProps {
  setAuthStep: (step: AuthStepName) => void
  setPhoneNumberProp: (phoneNumber: string) => void
  plan: Plan | null
}

export function EnhancedAuthPhoneNumber({
  setAuthStep,
  setPhoneNumberProp,
  plan,
}: EnhancedAuthPhoneNumberProps): JSX.Element {
  const [searchParams] = useSearchParams()
  const routeParams = useParams()
  const searchParamChannelId = searchParams.get('id')
  const routeParamPlanId = routeParams.id
  const planId = channelIdToPlanId(searchParamChannelId || routeParamPlanId)
  const location = useLocation()

  const [phoneNumber, setPhoneNumber] = useState<E164Number | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [errorCode, setErrorCode] = useState('')
  const [inAppBrowser, setInAppBrowser] = useState(false)

  useEffect(() => {
    createMixpanelEvent(MixpanelEvent.viewSignUp, {
      pathname: location.pathname,
      platform: 'web',
      planId: planId ?? '',
    })
  }, [planId, location])

  useEffect(() => {
    if (errorCode) {
      const timer = setTimeout(() => {
        setErrorCode('')
      }, 5000)

      return () => clearTimeout(timer)
    } else {
      return setErrorCode('')
    }
  }, [errorCode])

  useEffect(() => {
    const auth = getAuth()
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
        'expired-callback': () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          if (window.grecaptcha && window.recaptchaWidgetId !== undefined) {
            window.grecaptcha.reset(window.recaptchaWidgetId)
          }
        },
      })
      // Pre-render the reCAPTCHA and store the widget ID
      window.recaptchaVerifier.render().then((widgetId: number) => {
        window.recaptchaWidgetId = widgetId
      })
    }
  }, [])

  useEffect(() => {
    setInAppBrowser(isInAppBrowser())
  }, [])

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault()

    createMixpanelEvent(MixpanelEvent.sendVerificationCodeClicked, {
      pathname: location.pathname,
      platform: 'web',
      ...(plan && {
        planId: plan.id,
        planTitle: plan.title,
      }),
    })

    setIsLoading(true)
    const appVerifier = window.recaptchaVerifier
    const auth = getAuth()
    appVerifier.verify().then(() => {
      signInWithPhoneNumber(auth, String(phoneNumber), appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult
          setPhoneNumberProp(String(phoneNumber))
          setAuthStep(AuthStepName.verificationCode)
          setIsLoading(false)

          // Log browser information after successful submission
          createTelemetryEvent('phoneNumberSubmitted', {
            userAgent: navigator.userAgent,
            platform: navigator.platform,
            language: navigator.language,
            screenWidth: window.screen.width,
            screenHeight: window.screen.height,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            pathname: location.pathname,
            planId: plan?.id || 'none',
          })
        })
        .catch((error) => {
          setErrorCode(error.code)
          logSmsError(error, phoneNumber)
          setIsLoading(false)

          // Log browser information even if there's an error
          createTelemetryEvent('phoneNumberSubmissionFailed', {
            userAgent: navigator.userAgent,
            platform: navigator.platform,
            language: navigator.language,
            screenWidth: window.screen.width,
            screenHeight: window.screen.height,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            pathname: location.pathname,
            planId: plan?.id || 'none',
            errorCode: error.code,
            errorMessage: error.message,
          })

          // Reset the reCAPTCHA in case of error
          if (window.grecaptcha && window.recaptchaWidgetId !== undefined) {
            window.grecaptcha.reset(window.recaptchaWidgetId)
          }
        })
    })
  }

  return (
    <article className="EnhancedAuth">
      <Header plan={plan} />
      <div className="EnhancedAuth__main">
        <form className="EnhancedAuth__form" onSubmit={handleSubmit}>
          <label>
            <PhoneInput
              className="EnhancedAuth__form__input-wrapper"
              countrySelectProps={{ unicodeFlags: true }}
              countryCallingCodeEditable={false}
              placeholder="phone number"
              defaultCountry="US"
              value={phoneNumber}
              onChange={setPhoneNumber}
              countries={['US', 'GB', 'CZ', 'CA', 'MX', 'PR', 'VI', 'GU', 'AS', 'MP', 'BR', 'PL']}
            />
          </label>
          <WhatIsPie />
          <footer className="EnhancedAuth__footer">
            {renderError(errorCode)}
            {isLoading ? (
              <Loader />
            ) : (
              <button className="EnhancedButton-primary Button-large Fill-width">accept & continue</button>
            )}
          </footer>
        </form>
        {inAppBrowser && (
          <div className="EnhancedAuth__browser-warning">
            <p>
              Having trouble? Open <strong>plan.getpie.app</strong> in your external browser.
            </p>
            <br />
            <OpenInBrowserLink />
          </div>
        )}
        <TermsAndService />
      </div>
    </article>
  )
}

function OpenInBrowserLink(): JSX.Element {
  const [userAgent, setUserAgent] = useState('')

  useEffect(() => {
    setUserAgent(navigator.userAgent || navigator.vendor || (window as any).opera)
  }, [])

  const url = window.location.href

  const handleOpenInBrowser = () => {
    if (/android/i.test(userAgent)) {
      // For Android devices
      const intentUrl = `intent://${url.replace(
        /^https?:\/\//,
        '',
      )}#Intent;scheme=https;package=com.android.chrome;end;`
      window.location.href = intentUrl
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      // For iOS devices, try to open in Chrome first
      const chromeUrl = `googlechrome://${url.replace(/^https?:\/\//, '')}`

      // Attempt to open Chrome
      window.location.href = chromeUrl

      // if Chrome isn't installed, show the alert after a delay
      setTimeout(() => {
        alert(
          'Tap the three dots in the upper-right hand corner and select "Open in external browser" for the best experience.',
        )
      }, 1500)
    } else {
      // Fallback
      window.open(url, '_blank')
    }
  }

  return (
    <button onClick={handleOpenInBrowser} className="Button-secondary Button-medium Fill-width">
      Open in Browser
    </button>
  )
}
