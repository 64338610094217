import { type CalendarVariantValue, CALENDAR_VARIANT } from './constants'
import GoogleLight from '../../assets/svg/GoogleLight.svg'
import Apple from '../../assets/svg/Apple.svg'
import Outlook from '../../assets/svg/Outlook.svg'
import { CalendarEvent, google, ics, outlook } from 'calendar-link'
import { useGenerateShareLinks } from '../../hooks/useGenerateShareLinks'
import { Plan } from '../../__generated__/graphql'

export interface CalendarLink {
  href: string
  alt: string
  logo: string
}

function isValidIsoTimestamp(timestamp: string) {
  // Regular expression to match ISO 8601 format
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})$/

  // Check if the string matches the ISO 8601 format
  if (!iso8601Regex.test(timestamp)) {
    return false
  }

  // Check if the Date object can parse it without returning "Invalid Date"
  const date = new Date(timestamp)
  return !isNaN(date.getTime())
}

export function getCalendarLinkProps(type: CalendarVariantValue, event: CalendarEvent): CalendarLink {
  if (!isValidIsoTimestamp(event.start)) {
    throw 'Start date for calendar event is not valid'
  }
  switch (type) {
    case CALENDAR_VARIANT.Google:
      return {
        href: google(event),
        logo: GoogleLight,
        alt: 'Google Logo',
      }
    case CALENDAR_VARIANT.Apple:
      return {
        href: ics(event),
        logo: Apple,
        alt: 'Apple Logo',
      }
    case CALENDAR_VARIANT.Outlook:
      return {
        href: outlook(event),
        logo: Outlook,
        alt: 'Outlook Logo',
      }
    default:
      return {
        href: '',
        logo: '',
        alt: '',
      }
  }
}

export function createCalendarEvent(plan: Plan): CalendarEvent {
  const { data } = useGenerateShareLinks(plan.id)
  const output = {
    title: plan.title,
    start: buildCalendarDateString(plan) || new Date().toISOString(),
    duration: plan.startTimeText ? ([3, 'hour'] as [number, 'hour']) : undefined,
    allDay: !plan.startTimeText,
    description: data?.planUsers?.generateShareLinks?.dynamicLinkUrl ?? '',
  }
  return output
}

export function buildCalendarDateString(plan: Plan): string | null {
  if (plan.dates && plan.dates.length === 1 && plan.dates[0].text) {
    const calendarDate = plan.dates[0].text
    if (plan.startTimeText) {
      const time = plan.startTimeText.split(',')[1] || ''
      return `${calendarDate} • ${time}`.trim()
    }
    return calendarDate
  }
  return null
}
