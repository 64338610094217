import { useDisablePageScroll } from '../../hooks/useDisablePageScroll'
import './BottomDrawer.scss'
import Offcanvas from 'react-bootstrap/Offcanvas'

const BottomDrawer = ({
  className,
  children,
  onHide,
  showCancelButton,
  shouldHideCloseButton = false,
  enhanced,
}: {
  className?: string
  children: any
  onHide: () => void
  showCancelButton?: boolean
  shouldHideCloseButton?: boolean
  enhanced?: boolean
}) => {
  useDisablePageScroll()

  if (enhanced) {
    return (
      <Offcanvas
        className="EnhancedBottomDrawer"
        placement="bottom"
        scroll={true}
        backdrop={true}
        show={true}
        onHide={onHide}
        animation={2.0}
      >
        {!shouldHideCloseButton && (
          <Offcanvas.Header className="EnhancedBottomDrawer__header">
            <button className="EnhancedBottomDrawer__Button" onClick={onHide}>
              close
            </button>
          </Offcanvas.Header>
        )}
        <Offcanvas.Body className="EnhancedBottomDrawer__body">{children}</Offcanvas.Body>
      </Offcanvas>
    )
  } else {
    return (
      <Offcanvas
        className={`BottomDrawer ${className}`}
        placement="bottom"
        scroll={true}
        backdrop={true}
        show={true}
        onHide={onHide}
      >
        {showCancelButton && (
          <Offcanvas.Header className="BottomDrawer__header Flex">
            <button className="Button-secondary Button-small" onClick={onHide}>
              Cancel
            </button>
          </Offcanvas.Header>
        )}
        <Offcanvas.Body className="BottomDrawer__body">
          <div className="Fill-width">{children}</div>
        </Offcanvas.Body>
      </Offcanvas>
    )
  }
}

export default BottomDrawer
