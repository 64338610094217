import './ChooseImage.scss'
import { ConfirmationButtons } from '../../ConfirmationButtons/ConfirmationButtons'
import { useQuery } from '@apollo/client'
import { planImagesFind } from '../../../graphql/queries/plan-images-find'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import { PlanEditView } from '../../../types/PlanEditView.enum'
import React from 'react'

const ChooseImage = ({
  setView,
  planImageUrl,
  setPlanImageUrl,
  setCustomImage,
}: {
  setView: (view: PlanEditView) => void
  planImageUrl: string
  setPlanImageUrl: (url: string) => void
  setCustomImage: (image: string | null) => void
}) => {
  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })

  // TODO: add loading state
  const { loading, data } = useQuery(planImagesFind)
  const imageUrls = data?.plans?.findPlanIconImageUrls || []

  const [picPreviewUrl, setPicPreviewUrl] = useState(planImageUrl)
  const [uploadedImage, setUploadedImage] = useState<File | string | null>(null)
  const [didError, setDidError] = useState<boolean>(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const onCancelClick = () => {
    setView(PlanEditView.planEdit)
  }
  const onContinueClick = () => {
    setView(PlanEditView.planEdit)

    if (uploadedImage) {
      // for custom images, we need to send a separate request to save the image
      setCustomImage((uploadedImage ?? null) as string | null)
    }
    setPlanImageUrl(picPreviewUrl)
  }

  const onImageUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0]
    if (!file) {
      return
    }

    const isFileTooBig = file.size > 10000000
    const allowedFileTypes = ['image/png', 'image/jpeg']
    const isFileNotAllowed = !allowedFileTypes.some((type) => file.type === type)
    if (isFileTooBig || isFileNotAllowed) {
      if (e?.target?.value) {
        e.target.value = ''
      }
      setDidError(true)
      let errorMessage = 'There is some kind of error preventing us from allowing you to upload your image'
      if (isFileTooBig) {
        errorMessage = 'The file you are trying to upload is to big, please reduce the filesize and try again.'
      }
      if (isFileNotAllowed) {
        errorMessage = `The file you are trying to upload is of type: "${file.type}".
        \nwhich is not allow for this platform. The file should either be a PNG or JPEG filetype\n`
      }

      const secondLine = '\nIf you have future questions please email: hello@pumpkinpie.com'
      alert(errorMessage + secondLine)

      return
    }

    setDidError(false)
    setPicPreviewUrl(URL.createObjectURL(file))
    setUploadedImage(file)
  }

  const choosePieImage = (e: any) => {
    setDidError(false)
    setPicPreviewUrl(e.target.src)
    setUploadedImage(null)
  }

  const column1Fragment = (
    <div className="ChooseImage__column-1 Flex Flex--col Flex--align-center">
      <button className="Button-secondary Button-medium Mb-2" onClick={() => fileInputRef.current?.click()}>
        <i className="bi bi-image Mr-1" />
        Use a Photo
      </button>
      <p className={didError ? 'Caption Pink-text ' : 'Caption'}>Image cannot exceed 20MB</p>
      {/* Hidden File Input, triggered via "Use a Photo" button */}
      <div style={{ height: '0px', overflow: 'hidden' }} className="Mt-12">
        <input type="file" accept="image/*" id="file-upload" onChange={onImageUploadChange} ref={fileInputRef} />
      </div>
      <h5>Or select an image</h5>
      <div className="ChooseImage__images-grid Mt-6">
        {loading && (
          <>
            <Skeleton className="ChooseImage__images-grid__loading-skeleton" />
            <Skeleton className="ChooseImage__images-grid__loading-skeleton" />
            <Skeleton className="ChooseImage__images-grid__loading-skeleton" />
            <Skeleton className="ChooseImage__images-grid__loading-skeleton" />
            <Skeleton className="ChooseImage__images-grid__loading-skeleton" />
            <Skeleton className="ChooseImage__images-grid__loading-skeleton" />
          </>
        )}
        {imageUrls.map((imageUrl: string) => (
          <div key={imageUrl}>
            <div
              className={`${
                picPreviewUrl === imageUrl
                  ? 'ChooseImage__images-grid__image ChooseImage__images-grid__image--selected'
                  : 'ChooseImage__images-grid__image'
              }`}
            >
              {picPreviewUrl === imageUrl && <div className="ChooseImage__images-grid__image__icon bi bi-check" />}
              <img src={imageUrl} onClick={choosePieImage} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const column2Fragment = (
    <div className="ChooseImage__sticky-image-preview">
      <div className="ChooseImage__column-2 Flex Flex--col Flex--align-center G-2">
        {<img className="ChooseImage__preview-image" src={picPreviewUrl} />}
        <p className="Caption">Preview</p>
      </div>
    </div>
  )

  return (
    <>
      <div className="ChooseImage Flex Flex--col Flex--align-center">
        {!isMobile && (
          <div className="Flex G-9">
            {column1Fragment}
            {column2Fragment}
          </div>
        )}
        {isMobile && (
          <>
            {column2Fragment}
            {column1Fragment}
          </>
        )}
      </div>
      <div className="ChooseImage__sticky-buttons">
        <ConfirmationButtons
          saveButtonText="Done"
          isDisabled={didError}
          onCancelClick={onCancelClick}
          onContinueClick={onContinueClick}
        />
      </div>
    </>
  )
}

export { ChooseImage }
