import { FormEvent, useEffect, useState } from 'react'
import ReactInputVerificationCode from 'react-input-verification-code'
import './AuthVerificationCode.scss'
import Loader from '../../Loader/Loader'
import { useMutation } from '@apollo/client'
import { UsersMutations } from '../../../__generated__/graphql'
import { usersSignUp } from '../../../graphql/mutations/users-sign-up'
import { store } from '../../../store/store'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from '@firebase/auth'
import { AuthStepName } from '../../../types/auth-step-name.enum'

const AuthVerificationCode = ({
  setAuthStep,
  phoneNumberProp,
}: {
  setAuthStep: (step: AuthStepName) => void
  phoneNumberProp: string
}) => {
  const [verificationCode, setVerificationCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isResendDisabled, setIsResendDisabled] = useState(false)
  const [resendCooldown, setResendCooldown] = useState(0)
  const [error, setError] = useState('')

  useEffect(() => {
    if (resendCooldown > 0) {
      const timer = setInterval(() => {
        setResendCooldown((prevCooldown) => prevCooldown - 1)
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    } else {
      return setIsResendDisabled(false)
    }
  }, [resendCooldown])

  const handleNumberInputsChange = (values: any) => {
    setVerificationCode(values)
  }

  const [signUpMutation] = useMutation<{ users: UsersMutations }>(usersSignUp)

  const handleVerifyCode = (e: FormEvent<HTMLFormElement>) => {
    setError('')
    e.preventDefault()
    setIsLoading(true)
    window.confirmationResult
      .confirm(verificationCode)
      .then(async () => {
        const response = await signUpMutation()
        if (response && response.data?.users.signUp) {
          const user = response.data.users.signUp
          const authToken = await getAuth().currentUser?.getIdToken()
          store.dispatch({ type: 'auth/setToken', authToken })
          store.dispatch({ type: 'user/upsert', user })
          if (!user.fullName) setAuthStep(AuthStepName.name)
          else if (!user.imageUrl) setAuthStep(AuthStepName.picture)
        }
        setIsLoading(false)
      })
      .catch((error: any) => {
        setError(error.code)
        setIsLoading(false)
      })
  }

  const handleChangePhoneNumber = () => {
    setAuthStep(AuthStepName.phoneNumber)
  }

  const handleResendCode = () => {
    if (isResendDisabled) return
    setError('')
    setIsResendDisabled(true)
    setResendCooldown(10)
    setIsLoading(true)
    generateRecaptcha()

    const appVerifier = window.recaptchaVerifier
    const auth = getAuth()
    signInWithPhoneNumber(auth, String(phoneNumberProp), appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult
        setIsLoading(false)
      })
      .catch((error) => {
        setError(error)
      })
  }

  const generateRecaptcha = () => {
    const auth = getAuth()
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
        'expired-callback': () => {
          // Response expired. Ask user to solve reCAPTCHA again.
        },
      })
    }
  }

  return (
    <div className="VerificationCode">
      <h1 className="VerificationCode__header-icon Header-1">🔑</h1>
      <h2 className="VerificationCode__header-text Header-2">Enter the code we sent to {phoneNumberProp}</h2>
      <form className="VerificationCode__form" onSubmit={handleVerifyCode}>
        <div className="VerificationCode__form__input-wrapper">
          <ReactInputVerificationCode placeholder="" autoFocus={true} length={6} onChange={handleNumberInputsChange} />
        </div>
        <div className="VerificationCode__form__footer">
          <div className="VerificationCode__form__footer__secondary-btns-wrapper">
            <button
              className={`Button-medium Button-secondary${isResendDisabled ? '--disabled' : ''} Fill-width`}
              onClick={handleResendCode}
            >
              Resend code
            </button>
            <button className="Button-secondary Button-medium Fill-width" onClick={handleChangePhoneNumber}>
              Change number
            </button>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <button className="VerificationCode__form__footer__verify-btn Button-primary Button-large">
              Accept and Verify code
            </button>
          )}
          {error && error === 'auth/invalid-verification-code' && (
            <p className="VerificationCode__form__footer__error-text Pink-text">Wrong code, please enter it again</p>
          )}
          {error && error !== 'auth/invalid-verification-code' && (
            <p className="VerificationCode__form__footer__error-text">Something went wrong</p>
          )}
        </div>
      </form>

      <p className="VerificationCode__terms">
        Message and data rates may apply. <br />
        By continuing you agree to Pie’s{' '}
        <a className="VerificationCode__terms__link" href="https://www.getpie.app/terms-of-service" target="_blank">
          Terms of Service
        </a>{' '}
        and{' '}
        <a className="VerificationCode__terms__link" href="https://www.getpie.app/privacy-policy" target="_blank">
          Privacy Policy
        </a>
        , and consent to receive text messages from Pie.
      </p>
    </div>
  )
}

export { AuthVerificationCode }
